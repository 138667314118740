// https://icon-sets.iconify.design/
import type { SVGProps } from "react";

import { cn } from "@wind/ui";

import AlertIcon from "~icons/ant-design/alert-outlined";
import BsBuilding from "~icons/bi/building";
import BsCheck from "~icons/bi/check";
import BsDoorOpen from "~icons/bi/door-open";
import BiFilter from "~icons/bi/filter";
import BsFillGrid3X3GapFill from "~icons/bi/grid-3x3-gap-fill";
import HourglassIcon from "~icons/bi/hourglass";
import BsJournalText from "~icons/bi/journal-text";
import BsPeople from "~icons/bi/people";
import BsPerson from "~icons/bi/person";
import BsStars from "~icons/bi/stars";
import DollarIcon from "~icons/bx/dollar";
import ErrorIcon from "~icons/codicon/error";
import FaEnvelope from "~icons/fa/envelope";
import FaPodcast from "~icons/fa/podcast";
import FaSlack from "~icons/fa/slack";
import TrashIcon from "~icons/fa/trash-o";
import TestIcon from "~icons/fluent-mdl2/test-beaker-solid";
import ColumnsIcon from "~icons/fluent/column-triple-24-regular";
import ConferenceRoom from "~icons/fluent/conference-room-20-regular";
import GlobeIcon from "~icons/fluent/globe-20-regular";
import TeamIcon from "~icons/fluent/people-community-48-regular";
import SpinnerIcon from "~icons/fluent/spinner-ios-16-regular";
import NumberBoxIcon from "~icons/formkit/number";
import OrgChartIcon from "~icons/game-icons/organigram";
import HashTagIcon from "~icons/heroicons-outline/hashtag";
import Document from "~icons/heroicons/document";
import BarChartIcon from "~icons/ic/baseline-bar-chart";
import ChevronRightIcon from "~icons/ic/outline-chevron-right";
import MdOutlineSyncAlt from "~icons/ic/outline-sync-alt";
import LinkIcon from "~icons/ic/round-link";
import PlusIcon from "~icons/ic/round-plus";
import FaSync from "~icons/ic/twotone-sync";
import ArcheryMatchIcon from "~icons/iconoir/archery-match";
import LeaderboardIcon from "~icons/iconoir/leaderboard";
import SmsIcon from "~icons/iconoir/message-text";
import EmailIcon from "~icons/iconoir/send-mail";
import AnalyticsIcon from "~icons/ion/analytics-outline";
import BiVideoRecording from "~icons/ion/recording-outline";
import TrendingDownIcon from "~icons/ion/trending-down-sharp";
import TrendingUpIcon from "~icons/ion/trending-up-sharp";
import BotIcon from "~icons/lucide/bot";
import LayersIcon from "~icons/lucide/layers";
import PencilIcon from "~icons/lucide/pencil";
import TableIcon from "~icons/lucide/table-properties";
import UnlinkIcon from "~icons/lucide/unlink";
import FeedbackIcon from "~icons/material-symbols-light/chat-outline";
import CardIcon from "~icons/material-symbols-light/id-card-outline";
import LockIcon from "~icons/material-symbols-light/lock-outline";
import SyncIcon from "~icons/material-symbols-light/sync-alt";
import KeyboardSingleArrowRight from "~icons/material-symbols/keyboard-arrow-right";
import KeyboardDoubleArrowRight from "~icons/material-symbols/keyboard-double-arrow-right";
import MergeIcon from "~icons/material-symbols/merge";
import MdSearch from "~icons/material-symbols/search";
import MdThermostat from "~icons/material-symbols/thermostat";
import UndoIcon from "~icons/material-symbols/undo";
import PlusCircleIcon from "~icons/mdi-light/plus-circle";
import BellOffIcon from "~icons/mdi/bell-off-outline";
import BellRingIcon from "~icons/mdi/bell-ring";
import BrainIcon from "~icons/mdi/brain";
import MdCheck from "~icons/mdi/check";
import MdOutlineCheck from "~icons/mdi/check-circle-outline";
import MdOutlineCircle from "~icons/mdi/circle-outline";
import MdOutlineHighlightOff from "~icons/mdi/close-circle-outline";
import DotIcon from "~icons/mdi/dot";
import ThreeDotsVerticalIcon from "~icons/mdi/dots-vertical";
import EyeIcon from "~icons/mdi/eye-outline";
import InfoCircleIcon from "~icons/mdi/info-circle-outline";
import PersonCheck from "~icons/mdi/person-check-outline";
import PersonMultipleCheck from "~icons/mdi/person-multiple-check-outline";
import RefreshIcon from "~icons/mdi/refresh";
import TimelineClockIcon from "~icons/mdi/timeline-clock-outline";
import ArrowToDownLine from "~icons/mingcute/arrow-to-down-line";
import ArrowToUpLine from "~icons/mingcute/arrow-to-up-line";
import GearIcon from "~icons/octicon/gear-24";
import PlayCircleIcon from "~icons/octicon/play-24";
import HorizontalControlsIcon from "~icons/oui/controls-horizontal";
import ThumbsDownIcon from "~icons/pepicons-print/thumbs-down";
import ThumbsUpIcon from "~icons/pepicons-print/thumbs-up";
import PaintBrushIcon from "~icons/ph/paint-brush";
import WrenchIcon from "~icons/ph/wrench";
import ChevronDownPixelIcon from "~icons/pixelarticons/chevron-down";
import ArrowUpBaseIcon from "~icons/radix-icons/arrow-up";
import BellIcon from "~icons/radix-icons/bell";
import CalendarIcon from "~icons/radix-icons/calendar";
import ClockIcon from "~icons/radix-icons/clock";
import CodeIcon from "~icons/radix-icons/code";
import CopyIcon from "~icons/radix-icons/copy";
import FullScreenIcon from "~icons/radix-icons/enter-full-screen";
import HamburgerIcon from "~icons/radix-icons/hamburger-menu";
import HomeIcon from "~icons/radix-icons/home";
import LightningBoltIcon from "~icons/radix-icons/lightning-bolt";
import PaperPlaneIcon from "~icons/radix-icons/paper-plane";
import StarIcon from "~icons/radix-icons/star";
import StarFilledIcon from "~icons/radix-icons/star-filled";
import KeyIcon from "~icons/solar/key-linear";
import MailboxIcon from "~icons/solar/mailbox-linear";
import TagIcon from "~icons/solar/tag-outline";
import HeartbeatIcon from "~icons/tabler/activity-heartbeat";
import ChecklistIcon from "~icons/tabler/checklist";
import SwitchIcon from "~icons/tabler/switch-3";
import XIcon from "~icons/tabler/x";

const ArrowUpIcon = (props: SVGProps<SVGSVGElement> = {}) => (
  <ArrowUpBaseIcon className={cn(props.className, "text-[0.9em]")} {...props} />
);
const ArrowDownIcon = (props: SVGProps<SVGSVGElement> = {}) => (
  <ArrowUpIcon className={cn(props.className, "rotate-180")} {...props} />
);
const ArrowLeftIcon = (props: SVGProps<SVGSVGElement> = {}) => (
  <ArrowUpIcon className={cn(props.className, "-rotate-90")} {...props} />
);

const ArrowRightIcon = (props: SVGProps<SVGSVGElement> = {}) => (
  <ArrowUpIcon className={cn(props.className, "rotate-90")} {...props} />
);

const EditIcon = (props: SVGProps<SVGSVGElement> = {}) => <PencilIcon {...props} />;

const ComparisonIcon = (props: SVGProps<SVGSVGElement> = {}) => <ArcheryMatchIcon {...props} />;

const ActivityIcon = (props: SVGProps<SVGSVGElement> = {}) => <HeartbeatIcon {...props} />;

const NewActivityIcon = (props: SVGProps<SVGSVGElement> = {}) => <TimelineClockIcon {...props} />;

const EmployeesIcon = (props: SVGProps<SVGSVGElement> = {}) => <BsPeople {...props} />;

const CheckIcon = (props: SVGProps<SVGSVGElement> = {}) => <MdCheck {...props} />;

const EmployeeIcon = (props: SVGProps<SVGSVGElement> = {}) => <BsPerson {...props} />;

const CostIcon = (props: SVGProps<SVGSVGElement> = {}) => <DollarIcon {...props} />;

const MeetingsIcon = (props: SVGProps<SVGSVGElement> = {}) => <ConferenceRoom {...props} />;

const SummaryIcon = (props: SVGProps<SVGSVGElement> = {}) => <BsJournalText {...props} />;

const SyncIntegrationIcon = (props: SVGProps<SVGSVGElement> = {}) => <FaSync {...props} />;

const AiIcon = (props: SVGProps<SVGSVGElement> = {}) => <BsStars {...props} />;

const NewTabIcon = (props: SVGProps<SVGSVGElement> = {}) => (
  <ArrowRightIcon {...props} className={cn(props.className, "-rotate-45")} />
);

const IntegrationsIcon = (props: SVGProps<SVGSVGElement> = {}) => <MdOutlineSyncAlt {...props} />;

const MappingIcon = (props: SVGProps<SVGSVGElement> = {}) => <MdOutlineSyncAlt {...props} />;

const ConnectionsIcon = (props: SVGProps<SVGSVGElement> = {}) => <SyncIcon {...props} />;

const ConnectIcon = (props: SVGProps<SVGSVGElement> = {}) => <SyncIcon {...props} />;

const DisconnectIcon = (props: SVGProps<SVGSVGElement> = {}) => <UnlinkIcon {...props} />;

const LogoutIcon = (props: SVGProps<SVGSVGElement> = {}) => <BsDoorOpen {...props} />;

const RecordingIcon = (props: SVGProps<SVGSVGElement> = {}) => <BiVideoRecording {...props} />;

const DocumentIcon = (props: SVGProps<SVGSVGElement> = {}) => <Document {...props} />;

const SearchIcon = (props: SVGProps<SVGSVGElement> = {}) => <MdSearch {...props} />;

const FilterIcon = (props: SVGProps<SVGSVGElement> = {}) => <BiFilter {...props} />;

const ChannelIcon = (props: SVGProps<SVGSVGElement> = {}) => <HashTagIcon {...props} />;

const ExternalLinkIcon = (props: SVGProps<SVGSVGElement> = {}) => (
  <ArrowUpIcon className={cn(props.className, "rotate-45")} />
);
const SettingsIcon = (props: SVGProps<SVGSVGElement> = {}) => <GearIcon {...props} />;

const HighlightsIcon = (props: SVGProps<SVGSVGElement> = {}) => <BsStars {...props} />;

const PersonIcon = (props: SVGProps<SVGSVGElement> = {}) => <BsPerson {...props} />;

const PersonCheckIcon = (props: SVGProps<SVGSVGElement> = {}) => <PersonCheck {...props} />;

const PersonMultipleCheckIcon = (props: SVGProps<SVGSVGElement> = {}) => (
  <PersonMultipleCheck {...props} />
);

const UserIcon = (props: SVGProps<SVGSVGElement> = {}) => <BsPerson {...props} />;

const CompanyIcon = (props: SVGProps<SVGSVGElement> = {}) => <BsBuilding {...props} />;

const OrgsIcon = (props: SVGProps<SVGSVGElement> = {}) => <BsBuilding {...props} />;

const FeaturesIcon = (props: SVGProps<SVGSVGElement> = {}) => <BsCheck {...props} />;

// Feature states
const FeatureOnIcon = (props: SVGProps<SVGSVGElement> = {}) => <MdOutlineCheck {...props} />;

const FeatureOffIcon = (props: SVGProps<SVGSVGElement> = {}) => (
  <MdOutlineHighlightOff {...props} />
);

const FeatureIndeterminateIcon = (props: SVGProps<SVGSVGElement> = {}) => (
  <MdOutlineCircle {...props} />
);

const EmailChannelIcon = (props: SVGProps<SVGSVGElement> = {}) => <FaEnvelope {...props} />;

const SlackChannelIcon = (props: SVGProps<SVGSVGElement> = {}) => <FaSlack {...props} />;

const PodcastChannelIcon = (props: SVGProps<SVGSVGElement> = {}) => <FaPodcast {...props} />;

const HealthIcon = (props: SVGProps<SVGSVGElement> = {}) => <MdThermostat {...props} />;

const DeliveryIcon = (props: SVGProps<SVGSVGElement> = {}) => <MailboxIcon {...props} />;

const HomeGridIcon = (props: SVGProps<SVGSVGElement> = {}) => <BsFillGrid3X3GapFill {...props} />;

const ChevronDownIcon = (props: SVGProps<SVGSVGElement> = {}) => (
  <ChevronRightIcon {...props} className={cn(props.className, "rotate-90")} />
);

const HeroNumberIcon = (props: SVGProps<SVGSVGElement> = {}) => <NumberBoxIcon {...props} />;

const LoadingIcon = (props: SVGProps<SVGSVGElement> = {}) => (
  <SpinnerIcon {...props} className={cn(props.className, "animate-spin")} />
);

const TimeSpentIcon = (props: SVGProps<SVGSVGElement> = {}) => (
  <HourglassIcon className={cn(props.className, "text-[0.9em]")} {...props} />
);

const ImpactIcon = (props: SVGProps<SVGSVGElement> = {}) => <LightningBoltIcon {...props} />;

const NotificationsIcon = (props: SVGProps<SVGSVGElement> = {}) => <BellIcon {...props} />;

const SendIcon = (props: SVGProps<SVGSVGElement> = {}) => <PaperPlaneIcon {...props} />;

const ChooseStatsIcon = (props: SVGProps<SVGSVGElement> = {}) => (
  <HorizontalControlsIcon {...props} />
);

const SavedViewIcon = LayersIcon;

export {
  ActivityIcon,
  AlertIcon,
  NewActivityIcon,
  AiIcon,
  AnalyticsIcon,
  ArrowDownIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  ArrowUpIcon,
  ArrowToUpLine,
  ArrowToDownLine,
  BarChartIcon,
  BellOffIcon,
  BellRingIcon,
  BrainIcon,
  BotIcon,
  EmailChannelIcon,
  PodcastChannelIcon,
  SlackChannelIcon,
  CalendarIcon,
  CardIcon,
  ChannelIcon,
  CheckIcon,
  ChecklistIcon,
  ChevronDownIcon,
  ChevronDownPixelIcon,
  ChevronRightIcon,
  ChooseStatsIcon,
  ClockIcon,
  CodeIcon,
  ColumnsIcon,
  CompanyIcon,
  ConnectIcon,
  ConnectionsIcon,
  CopyIcon,
  ComparisonIcon,
  CostIcon,
  DeliveryIcon,
  DisconnectIcon,
  DocumentIcon,
  DotIcon,
  EditIcon,
  EmailIcon,
  EmployeeIcon,
  EmployeesIcon,
  ErrorIcon,
  ExternalLinkIcon,
  EyeIcon,
  FeatureIndeterminateIcon,
  FeatureOffIcon,
  FeatureOnIcon,
  FeaturesIcon,
  FeedbackIcon,
  FilterIcon,
  FullScreenIcon,
  GlobeIcon,
  HamburgerIcon,
  HealthIcon,
  HeroNumberIcon,
  HighlightsIcon,
  HomeGridIcon,
  HomeIcon,
  InfoCircleIcon,
  IntegrationsIcon,
  ImpactIcon,
  KeyIcon,
  KeyboardDoubleArrowRight,
  KeyboardSingleArrowRight,
  LeaderboardIcon,
  LinkIcon,
  LightningBoltIcon,
  LoadingIcon,
  LockIcon,
  LogoutIcon,
  MailboxIcon,
  MappingIcon,
  MeetingsIcon,
  MergeIcon,
  NewTabIcon,
  NotificationsIcon,
  OrgChartIcon,
  OrgsIcon,
  PaintBrushIcon,
  PencilIcon,
  PersonIcon,
  PersonCheckIcon,
  PersonMultipleCheckIcon,
  PlayCircleIcon,
  PlusCircleIcon,
  PlusIcon,
  RecordingIcon,
  RefreshIcon,
  SavedViewIcon,
  SearchIcon,
  SpinnerIcon,
  SettingsIcon,
  SendIcon,
  SmsIcon,
  StarFilledIcon,
  StarIcon,
  SummaryIcon,
  SwitchIcon,
  SyncIntegrationIcon,
  TableIcon,
  TagIcon,
  TeamIcon,
  TestIcon,
  TimeSpentIcon,
  ThreeDotsVerticalIcon,
  ThumbsDownIcon,
  ThumbsUpIcon,
  TrashIcon,
  TrendingUpIcon,
  TrendingDownIcon,
  UndoIcon,
  UserIcon,
  WrenchIcon,
  XIcon,
};
